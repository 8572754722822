<template>
	<div>
		<form @submit.prevent="$emit('step2', bid_bond)">
			<div class="form-group">
				<label>Performance Bond Amount</label>
				<vue-numeric v-model="bid_bond.amount" class="form-control" placeholder="Amount" />
			</div>
			<div class="form-group">
				<label>Tender Period</label>
				<select v-model="bid_bond.period" class="form-control">
					<option value="" disabled selected>Please select</option>
					<template v-for="group in Math.ceil(39 / 3)">
					<template v-if="group <= 14">
						<option :value="group * 3" :key="group">{{ getGroupLabel(group) }}</option>
					</template>
					<optgroup v-else :label="getGroupLabel(group)" :key="group">
						<option v-for="month in 3" :value="((group - 1) * 3) + month" :key="((group - 1) * 3) + month">
						{{ getTimelineText(((group - 1) * 3) + month) }}
						</option>
					</optgroup>
					</template>
				</select>
			</div>
			<div class="form-group">
				<label>Start Date</label>
				<date-picker v-model="bid_bond.start_date" format="yyyy-MM-dd" placeholder="Start Date" name="bid_bond_start_date" input-class="form-control bg-white" :disabledDates="disabledDates" />
			</div>
			<div class="form-group">
				<label>End Date</label>
				<input type="text" class="form-control" v-model="end_date" readonly="" placeholder="End Date">
			</div>
			<div class="form-group">
				<a href="" class="btn btn-info pull-left" @click.prevent="$emit('back')">Previous</a>
				<button class="btn btn-secondary pull-right">Next</button>
			</div>
		</form>
	</div>
</template>

<script>
	import moment from 'moment'
	import DatePicker from 'vuejs-datepicker';

	export default {
		components: {
			DatePicker
		},

		mounted() {
			this.current.amount ? this.bid_bond.amount = this.current.amount : this.bid_bond.amount = '';
			this.current.period ? this.bid_bond.period = this.current.period : this.bid_bond.period = '';
			this.current.start_date ? this.bid_bond.start_date = this.current.start_date : this.bid_bond.start_date = '';
		},
	
		data() {
			return {
				bid_bond: {
					amount: '',
					period: '',
					start_date: '',
					end_date: ''
				},
				disabledDates: {
					to: new Date(Date.now() - 8640000)
				}
			}
		},

		props: {
			current: {
				type: Object,
				default() {
					return {}
				}
			}
		},

		computed: {
			end_date() {
				if (this.bid_bond.start_date && this.bid_bond.period) {
					const months = parseInt(this.bid_bond.period);
					const endDate = moment(this.bid_bond.start_date).add(months, 'months');
					return endDate.format('YYYY-MM-DD');
				}

				return '';
			},
			getTimelineText() {
				return function (month) {
				if (month === 1) {
					return '1 month';
				} else {
					return `${month} months`;
				
				}
			}

       }
		},

		methods:{
			getGroupLabel(group) {
                return group * 3 + ' months';
             },
		},
		watch: {
			end_date(newval) {
				this.bid_bond.end_date = newval;
			},

			current(newval) {
				newval.amount ? this.bid_bond.amount = newval.amount : this.bid_bond.amount = '';
				newval.period ? this.bid_bond.period = newval.period : this.bid_bond.period = '';
				newval.start_date ? this.bid_bond.start_date = newval.start_date : this.bid_bond.start_date = '';
			}
		}
	}
</script>