<template>
  <div>
    <form @submit.prevent="$emit('step1', bid_bond)">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Company Name</label>
            <vue-select
              :options="companies"
              label="name"
              v-model="bid_bond.company"
              placeholder="Select Company"
              required
            ></vue-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Procuring Entity</label>
            <vue-select
              :options="counter_parties"
              label="name"
              v-model="bid_bond.counter_party"
              placeholder="Select Counterparty"
              required
            ></vue-select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Tender Number</label>
            <input type="text" class="form-control" v-model="bid_bond.tender_number" required placeholder="Tender Number" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Tender Purpose</label>
            <textarea rows="3" class="form-control" v-model="bid_bond.tender_purpose" required placeholder="Tender Purpose"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Addressee</label>
            <textarea rows="3" required class="form-control" v-model="bid_bond.addressee" placeholder="Eg, The Director General"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <button class="btn btn-secondary pull-right">Next</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  mounted() {
    this.bid_bond = this.current;
  },

  data() {
    return {
      bid_bond: {
        company: "",
        counter_party: "",
        tender_number: "",
        tender_purpose: "",
        addressee: ""
      }
    };
  },

  props: {
    companies: {
      type: Array,
      default() {
        return [];
      }
    },

    counter_parties: {
      type: Array,
      default() {
        return [];
      }
    },

    current: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  watch: {
    current(newval) {
      this.bid_bond = newval;
    }
  }
};
</script>