<template>
	<div>
		<form @submit.prevent="$emit('step3')">
			<h4>Summary</h4>
			<hr> 
			<div class="row" v-if="is_valid">
				<div class="col-md-6">
					<div class="bond-content">
						<p>Company: <span>{{ bid_bond.company.name }}</span></p>
						<p>Procuring Entity: <span>{{ bid_bond.counter_party.name }}</span></p>
						<p>Tender Number: <span>{{ bid_bond.tender_number }}</span></p>
					</div>
				</div>
				<div class="col-md-6">
					<div class="bond-content">
						<p>Tender Purpose</p>
						<p><span>{{ bid_bond.tender_purpose }}</span></p>
						<hr>
						<p>Addressee</p>
						<p><span>{{ bid_bond.addressee }}</span></p>
					</div>
				</div>
			</div>
			<hr>
			<div class="row" v-if="is_valid">
				<div class="col-md-6">
					<div class="bond-content">
						<p>Tender Period: <span>{{ bid_bond.period }}</span></p>
						<p>Start Date: <span>{{ start_date }}</span></p>
						<p>End Date: <span>{{ bid_bond.end_date }}</span></p>
					</div>
				</div>
				<div class="col-md-6">
					<div class="bond-content">
						<p>PerformanceBond Amount: <span>KES {{ $number.format(bid_bond.amount) }}</span></p>
						<p>PerformanceBond Charge: <span>KES {{ $number.format(pricing.performancebond_charge) }}</span></p>
						<p>Cash Cover: <span>KES {{ $number.format(pricing.cash_cover) }}</span></p>
						<p>Total: <span>KES {{ $number.format(pricing.total) }}</span></p>
					</div>
				</div>
			</div>
			<br v-if="is_valid">
			<div v-if="! is_valid" class="alert alert-danger">
				<p>The Performance Bond amount is invalid. Please try a different amount.</p>
			</div>
			<div class="form-group">
				<a href="" class="btn btn-info pull-left" @click.prevent="$emit('back')">Previous</a>
				<button class="btn btn-secondary pull-right" v-if="is_valid">Confirm</button>
			</div>
		</form>
	</div>
</template>

<script>
	import moment from 'moment'
	
	export default {
		mounted() {
			
		},

		props: {
			bid_bond: {
				type: Object,
				default() {
					return {
						company: {},
						counter_party: {}
					}
				}
			},
			pricing: {
				type: Object,
				default() {
					return {}
				}
			}
		},

		computed: {
			start_date() {

				return moment(this.bid_bond.start_date).format('YYYY-MM-DD')
				
			},

			is_valid() {

				return this.pricing.performancebond_charge != null;

			}
		}
	}
</script>